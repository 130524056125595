.form-dashboard .section-body .rt-candidate-information-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box {
  flex: 0 0 45%;
  border: 1px solid var(--border-color);
  padding: 1rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-candidate-div-main {
  margin-bottom: 1rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box b {
  font-weight: 800;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-lable {
  font-weight: 600;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-lable,
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-lable-main,
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-value {
  font-size: 0.875rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-lable-main {
  font-weight: bold;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-candidate-list {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 1.5rem;
  gap: 1.5rem;
  max-height: 20rem;
  overflow: auto;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-candidate-list .rt-candidate-div:last-child {
  margin-left: 13px;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box .rt-item-value {
  margin-top: 0.3rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-box svg {
  width: 15px;
  height: 15px;
  margin-bottom: 0.1rem;
}
.form-dashboard .section-body .rt-candidate-information-container .rt-candidate-information-personal {
  flex: 0 0 30%;
}
.form-dashboard .section-body .rt-candidate-information-container a::after {
  vertical-align: middle;
  content: url(/assets/catalyst/img/link.svg);
  margin-right: 0.1rem;
}